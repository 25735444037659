<!-- 单个视频 poster="http://open.ys7.com/asdf.jpg"-->
<template>
  <div id="video">
    <div class="view">
      <video id="player" muted autoplay controls playsInline webkit-playsinline style="width: 100%; height: auto;" :src="crrVideo.url">
        <source :src="crrVideo.url" type="application/x-mpegURL">
      </video>
    </div>
    <!-- 控制——水平 -->
    <div class="control1" style="display: none;">
      <div class="part1">
        <p>预览控制</p>
        <el-button type="primary" icon="el-icon-video-play" size="small">播放</el-button>
        <el-button type="primary" plain icon="el-icon-video-pause" size="small">停止</el-button>
      </div>
      <div class="part2">
        <p>方向控制</p>
        <div class="item">
          <el-button type="primary" icon="el-icon-arrow-up" circle size="mini"></el-button>
        </div>
        <div class="item">
          <el-button type="primary" icon="el-icon-arrow-left" circle size="mini"></el-button>
          <span>方向</span>
          <el-button type="primary" icon="el-icon-arrow-right" circle size="mini"></el-button>
        </div>
        <div class="item">
          <el-button type="primary" icon="el-icon-arrow-down" circle size="mini"></el-button>
        </div>
      </div>
      <div class="part2">
        <p></p>
        <br /><br /><br /><br />
        <div class="item">
          <el-button type="primary" icon="el-icon-minus" circle size="mini"></el-button>
          <span>缩放</span>
          <el-button type="primary" icon="el-icon-plus" circle size="mini"></el-button>
        </div>
      </div>
    </div>
    <!-- 控制——垂直 -->
    <div class="control2">
      <template v-if="videoArr.length > 1">
        <p>摄像头切换</p>
        <div class="item" style="margin: 3px 0;">
          <span style="margin: 0 5px;">切换:</span>
          <el-dropdown szie="small" placement="bottom" @command="handleDownCommand">
            <span class="el-dropdown-link" style="font-size:13px;margin: 0 5px;">
              <i class="el-icon-video-play"></i>摄像头{{crrNum+1}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) of videoArr" :command="index" v-bind:key="index" icon="el-icon-video-play">摄像头{{index+1}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
      <p>预览控制</p>
      <div style="display: flex; flex-direction: row;">
        <el-button type="primary" icon="el-icon-video-play" size="small" @click="control('play')">播放</el-button>
        <el-button type="primary" plain icon="el-icon-video-pause" size="small" @click="control('pause')">停止
        </el-button>
      </div>
      <br />
      <p>云台控制</p>
      <div class="item">
        <el-button type="primary" icon="el-icon-arrow-up" circle size="mini" @mousedown.native="onControl('up')" @mouseup.native="onControl('stop')" @mouseout.native="onControl('stop')">
        </el-button>
      </div>
      <div class="item">
        <el-button type="primary" icon="el-icon-arrow-left" circle size="mini" @mousedown.native="onControl('left')" @mouseup.native="onControl('stop')" @mouseout.native="onControl('stop')">
        </el-button>
        <span>方向</span>
        <el-button type="primary" icon="el-icon-arrow-right" circle size="mini" @mousedown.native="onControl('right')" @mouseup.native="onControl('stop')" @mouseout.native="onControl('stop')">
        </el-button>
      </div>
      <div class="item">
        <el-button type="primary" icon="el-icon-arrow-down" circle size="mini" @mousedown.native="onControl('down')" @mouseup.native="onControl('stop')" @mouseout.native="onControl('stop')">
        </el-button>
      </div>
      <!--激光-->
      <div v-if="rs485_laser" class="item" style="margin-top: 20px;">
        <span style="margin:0px">激光:</span>

        <span class="iconfont icon-on2 laser-hover" style="margin-left:10px" @click="onLaserControl('onLaser')"></span>
        <span class="iconfont icon-off2 laser-hover" style="margin:0px" @click="onLaserControl('offLaser')"></span>

      </div>
      <!--缩放-->
      <div class="item" style="margin-top: 20px;">
        <el-button type="primary" icon="el-icon-minus" circle size="mini" @mousedown.native="onControl('zoomOut')" @mouseup.native="onControl('stop')" @mouseout.native="onControl('stop')">
        </el-button>
        <span>缩放</span>
        <el-button type="primary" icon="el-icon-plus" circle size="mini" @mousedown.native="onControl('zoomIn')" @mouseup.native="onControl('stop')" @mouseout.native="onControl('stop')">
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ezuikit from '../common/hk1.3/1.3/ezuikit.js';

export default {
  data() {
    return {
      player: '',
      accessToken: '',
      gPtzRequestCache: '',
      rs485_laser: 0,	    //是否支持激光(此功能非萤石自带) 1-支持 0-不支持
      laserStatus: -1,    //激光开启状态 1-开启 0-关闭 -1 未操作
      crrVideo: {},       //多路摄像头情况，当前一组
      videoArr: [],       //多路摄像头
      crrNum: 0,          //默认第一路摄像头
    }
  },
  props: {
    data: {
      type: Object,
      default: {
        url: '',
        id: '',
      }
    }
  },
  watch: {
    '$store.state.accessToken': {
      handler() {
        this.accessToken = this.$store.state.accessToken;
      }
    },
    'data.url': {
      handler(newVal) {
        if (newVal) {
          //清理
          this.crrVideo = {}
          this.videoArr = []
          this.crrNum = 0
          //判断是否为多组摄像头,以逗号分隔。
          const url = this.data.url.split(",")  //多组数据以,分隔
          const id = this.data.id.split(",")
          if (url.length > 0) {
            url.filter((item, index) => {
              this.videoArr.push({
                id: id[index],
                url: item
              })
            })
          }
          //默认摄像头
          this.crrVideo = this.videoArr[this.crrNum]
          this.startVideo();
          this.getDeviceLaser();//获取激光
        }
      },
      deep: true
    }
  },
  methods: {
    startVideo() {
      if (this.player) {
        this.player.stop();
        this.player = null;
      }
      setTimeout(() => {
        // this.data.url = "http://hls01open.ys7.com/openlive/eaded213b54a421a901e5ca723bb3e9f.m3u8";
        // this.data.id = "E78820755";
        this.player = new EZUIPlayer('player');
      }, 1000)
    },
    control(type) {
      switch (type) {
        case 'play':
          if (this.player) {
            this.player.play();
          }
          break;
        case 'pause':
          if (this.player) {
            this.player.pause();
          }
          break;
        default:
          break;
      }
    },
    // 视频云台控制
    stopPtz() {
      if (this.gPtzRequestCache.length == 0) {
        return;
      }

      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // console.log("onreadystatechange: " + xhr.readyState);
        if (xhr.readyState == 4) {
          //console.log("xhr.responseText=" + xhr.responseText);
          //console.log("xhr.responseBody=" + xhr.responseBody);
        }
      }
      xhr.open("POST", "https://open.ys7.com/api/lapp/device/ptz/stop");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send(this.gPtzRequestCache);
      this.gPtzRequestCache = "";
    },
    movePtz(cameraId, dir) {
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        // console.log("onreadystatechange: " + xhr.readyState);
        if (xhr.readyState == 4) {
          //console.log("xhr.responseText=" + xhr.responseText);
          //console.log("xhr.responseBody=" + xhr.responseBody);
        }
      }
      xhr.open("POST", "https://open.ys7.com/api/lapp/device/ptz/start");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      //channelNo固定为1
      //文档中说speed云台速度：0-慢，1-适中，2-快,实测试发现speed=0时云台不动
      var data = "accessToken=" + this.accessToken + "&deviceSerial=" + cameraId + "&channelNo=1&direction=" +
        dir + "&speed=1";
      this.gPtzRequestCache = data;
      // console.log("req=" + data);
      xhr.send(data);

      //经测试，能控制云台，但chrome报No 'Access-Control-Allow-Origin' header is present on the requested resource.
      //Origin 'http://xxx' is therefore not allowed access.
      //获取不到执行结果
    },
    onControl(dir) {
      console.log('云控制台', dir)
      if (dir == "up") {
        dir = "0";
      } else if (dir == "down") {
        dir = "1";
      } else if (dir == "left") {
        dir = "2";
      } else if (dir == "right") {
        dir = "3";
      } else if (dir == "stop") {
        dir = "4";
      }

      if (this.common.featureEnabled('huaweiVideo')) {
        if (dir == "zoomIn") {
          dir = "5";
        } else if (dir == "zoomOut") {
          dir = "6";
        }
        // 华为
        let len = 2; // 速度【步长(1-10)】
        let pattern = 2; // 1、点动模式 2、连续模式
        let opnstion_text = [
          'PTZ_UP', // 云台向上
          'PTZ_DOWN', // 云台向下
          'PTZ_LEFT', // 云台向左
          'PTZ_RIGHT', // 云台向右
          'PTZ_STOP', // 云台停止
          'PTZ_LENS_ZOOM_OUT', // 范围缩大
          'PTZ_LENS_ZOOM_IN', // 范围放小
        ];
        // 发送
        let json = {
          "cmd": 'ptzControl',
          "uid": this.crrVideo.id,
          "opCode": opnstion_text[dir],
          "param1": pattern,
          "param2": len
        };
        console.log(`${opnstion_text[dir]}----`, json);
        this.ws.send(json);
      } else {
        // 海康
        if (dir == '4') { // stop
          return this.stopPtz();
        } else if (dir == "zoomIn") {
          dir = "8";
        } else if (dir == "zoomOut") {
          dir = "9";
        }

        this.movePtz(this.crrVideo.id, dir);

      }
    },
    //激光控制
    onLaserControl(dir) {
      if (dir == "onLaser") {
        dir = 1
      } else if (dir == "offLaser") {
        dir = 0
      }
      this.handleLaser(dir)
    },

    //开启、关闭激光
    handleLaser(type) {
      let uid = this.data.uid;
      let rs485_laser = this.rs485_laser;
      let data = type == 0 ? 'FF010400000005' : 'FF010200000003'    //预设码 不通用 FF010400000005-关闭 FF010200000003-打开
      if (rs485_laser == '1') {
        let json = {
          "cmd": "pushEvent",
          "uid": uid,
          "tag": "send485",
          "bus": "busA",
          "data": data
        };
        this.ws.send(json);
      }

    },
    //获取设备是否支持激光
    getDeviceLaser() {
      let json = {
        "cmd": "dataStore",
        "id": this.data.uid,
        "tag": 'hik',	//laser 激光
      };

      this.ws.send(json);
    },
    //激光存储设置
    on_dataStoreAck(that, res) {
      if (res == null || res.error != 0 || res.tag != "hik") {
        this.rs485_laser = 0
        return
      }
      let uid = this.data.uid
      let items = res.items
      let hikData = items.filter(item => { return item.id == uid })
      let { laser } = hikData[0].data
      if (laser == "1") {
        this.rs485_laser = 1
      }
    },

    // 监听
    onMsgFun() {
      // 华为操作面板
      this.ws.addCallback('pzcControlAck', (res) => {
        console.log('pzcControlAck---', res)
      })
      //激光设置
      this.ws.addCallback('dataStoreAck', (res) => {
        this.on_dataStoreAck(this, res)
      })
    },
    //多摄像头切换
    handleDownCommand(command) {
      // console.log(222, command);
      //当前设备不切换
      if (command != void 0 && command != this.crrNum) {
        this.crrNum = command
        this.crrVideo = this.videoArr[command]
        this.startVideo()
      }
    },
  },
  mounted() {
    this.accessToken = this.$store.state.accessToken;
    this.onMsgFun()
  }
}
</script>

<style lang="scss">
@import "../assets/iconfont/icon_live.css";
#video {
  display: flex;
  flex-direction: row;
  color: lighten($color: #000000, $amount: 40%);
  height: 100%;

  .view {
    flex: auto;
    height: 100%;
  }

  // 控制——水平
  .control1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .part1 {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 20px;

      .el-button {
        margin-bottom: 10px;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    .part2 {
      width: 150px;
      text-align: center;
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 6px 0;

      span {
        margin: 0 12px;
      }

      .el-button {
        width: inherit;
      }
    }

    .el-button {
      width: 100%;
    }
  }

  // 控制——垂直
  .control2 {
    width: 240px;
    flex-shrink: 0;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;

    p {
      margin: 26px 0;
      line-height: 1px;
      border-left: 40px solid lighten($color: $color_main, $amount: 30%);
      border-right: 40px solid lighten($color: $color_main, $amount: 30%);
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 10px 0;

      span {
        margin: 0 15px;
      }

      .el-button {
        width: inherit;
      }
      /*鼠标 滑过激光开关时效果 */
      .laser-hover:hover {
        font-weight: bold;
        cursor: pointer;
      }
    }

    .el-button {
      width: 100%;
    }
  }

  video {
    border-radius: 20px;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 13px;
  }
}
</style>
